var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-5",attrs:{"min-height":_vm.computedHeight}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row justify-md-start justify-center",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"d-flex flex-row justify-start align-center"},[_c('h2',[_vm._v(_vm._s(_vm.$t("multistop_show_quote_title")))]),_c('v-tooltip',{attrs:{"top":"","content-class":"bs-tooltip-top arrow","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-6 color-gray-text rounded-0",attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("multistop_show_quote_title_tooltip"))+" ")])])],1)])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.loaded)?_c('GoogleMapShow',{attrs:{"origin":_vm.map.stops[0],"stops":_vm.map.stops}}):_vm._e()],1)],1)],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row justify-md-start justify-center",attrs:{"cols":"12","md":"12"}},[_c('span',{staticClass:"d-flex flex-row justify-start align-center"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("multistop_list"))+" ")]),_c('v-tooltip',{attrs:{"top":"","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-6 color-gray-text rounded-0",attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("multistop_list_tooltip"))+" ")])])],1)])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('DataTable',{attrs:{"headers":_vm.table.headers,"loading":_vm.table.tableLoading,"items":_vm.table.items,"showPage":true}})],1)],1),_c('br'),_c('br'),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row justify-md-start justify-center",attrs:{"cols":"12","md":"12"}},[_c('span',{staticClass:"d-flex flex-row justify-start align-center"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("multistop_total_resume"))+" ")]),_c('v-tooltip',{attrs:{"top":"","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-6 color-gray-text rounded-0",attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("multistop_total_resume_tooltip"))+" ")])])],1)])],1),_c('v-row',{attrs:{"dense":""}},[(!_vm.table.tableLoading)?_c('v-col',{attrs:{"cols":"12"}},[_c('DataTableTotal',{attrs:{"headers":_vm.tableTotal.headers,"items":_vm.computedTableTotal,"showPage":true}})],1):_c('v-col',{staticClass:"d-flex flex-row justify-center align-center",attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1),_c('br'),_c('v-row',{staticClass:"d-flex flex-row justify-center",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-btn',{attrs:{"color":"success","block":""},on:{"click":function($event){return _vm.handleApproveMultiStop(_vm.quoteModal)}}},[_vm._v(" "+_vm._s(_vm.$t("multistop_approve"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }